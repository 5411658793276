import React, { useEffect, useState } from "react";

const imgStyle = {
  margin: "10px",
  maxWidth: "12%",
};

function PastPosters() {
  const [posters, setPosters] = useState([]);

  useEffect(() => {
    fetch(`/pastPosters.json?timestamp=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => setPosters(data));
  }, []);

  if (posters.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <h3 className='centered headerRad'>Προηγούμενες εμφανίσεις</h3>
      <p></p>
      <div className='sectionStyle'>
        {posters.map((poster) => (
          <img style={imgStyle} src={poster.name} alt={poster.name} />
        ))}
      </div>
      <hr />
    </>
  );
}

export default PastPosters;
