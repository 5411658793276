import React from "react";
import { Tooltip } from "react-tooltip";
import qr from "../images/insta_qr.png";
import "../App.css";

export default class InstaQR extends React.Component {
  state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = (event) => {
    if (this.dialog && !this.dialog.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      this.setState({ isOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <div>
        <i
          className='fab fa-instagram nav-link'
          onClick={this.handleShowDialog}
          alt='insta QR'
          data-tooltip-id='insta-tooltip'
        />
        <Tooltip id='insta-tooltip'>Click to see Instagram QR code</Tooltip>
        {this.state.isOpen && (
          <div className='overlay'>
            <dialog
              className='instaDialog'
              open
              ref={(node) => {
                this.dialog = node;
              }}
            >
              <a
                href='https://www.instagram.com/carmenventi_/'
                className='centered'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click for Instagram Page
              </a>
              <p className='centered'>or scan the QR to follow us</p>
              <img
                className='image'
                src={qr}
                alt='insta QR'
                data-tooltip-id='qr-tooltip'
              />
              <Tooltip id='qr-tooltip'>
                Scan this QR code to follow us on Instagram
              </Tooltip>
            </dialog>
          </div>
        )}
        <div className={this.state.isOpen ? "blur" : ""}></div>
      </div>
    );
  }
}
