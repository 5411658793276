import { useEffect, useState } from "react";
import Concert from "./Concert";

function FutureConcerts() {
  const [concerts, setConcerts] = useState([]);

  useEffect(() => {
    fetch(`/futureConcerts.json?timestamp=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => setConcerts(data));
  }, []);

  if (concerts.length === 0) {
    return <div></div>;
  }

  return (
    <div id='futureConcerts'>
      <h2 className='container centered headerRad'>Προσεχείς εμφανίσεις</h2>
      <p></p>
      <div className='row centered'>
        <div>
          {concerts.map((concert) => (
            <Concert concert={concert} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FutureConcerts;
