import mythos_poster from "../images/mythos_poster.jpg";
import Video from "./Video";

const praktoreioVid = {
  title: "Συνέντευξη στο Πρακτορείο FM 104,9",
  link: "https://www.youtube.com/embed/RyKwsIeSJSA?rel=0",
};

const ertVid = {
  title: "Συνέντευξη στη 'Δική μας πόλη'",
  link: "https://youtube.com/embed/oqT2dltVmZ0?rel=0",
};

function Interviews() {
  return (
    <>
      <div className='container'>
        <h2 className='centered, headerRad'>Συνεντεύξεις</h2>
        <p></p>
        <h2 className='centered'>
          <h3 className='centered'>
            Συνέντευξη στη 'Δική μας πόλη' της ΕΡΤ <br />
          </h3>
        </h2>
        <div className='centered'>
          <Video video={ertVid} />
        </div>
        <hr className='container' />
        <h3 className='centered'>
          Συνέντευξη στον Μύθο στα FM 93.4 <br />
        </h3>
        <div className='container, centered'>
          <img src={mythos_poster} alt='Mythos Interview' width='48%' />
        </div>
        <hr className='container' />
        <h3 className='container, centered'>
          Συνέντευξη στο Πρακτορείο FM 104,9
        </h3>
        <div className='centered'>
          <Video video={praktoreioVid} />
        </div>
        <hr className='container' />
        <h3 className='centered'>
          <a
            href='https://kulturosupa.gr/musicmania/arthra-sinentefxseis/in-tempo-me-tous-carmen-venti/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Συνέντευξη στην Kulturosupa
          </a>
        </h3>
      </div>
      <hr />
    </>
  );
}

export default Interviews;
