import React, { Component } from "react";

class Concert extends Component {
  render() {
    return (
      <div>
        <h3 className='centered'>{this.props.concert.overview}</h3>
        {this.props.concert.date && (
          <h5 className='centered'>
            {this.props.concert.description}
            <br />
          </h5>
        )}
        <div className='container, centered'>
          <img src={this.props.concert.poster} alt='Poster' width='48%' />
        </div>
        <hr className='container' />
      </div>
    );
  }
}

export default Concert;
