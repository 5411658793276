import { useEffect, useState } from "react";
import Video from "./Video";
import Short from "./Short";

function AllVideos() {
  const [allVideos, setAllVideos] = useState(null);

  useEffect(() => {
    fetch(`/allVideos.json?timestamp=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => setAllVideos(data));
  }, []);

  if (!allVideos) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div id='bandSongs' className='container'>
        <h2 className='container centered headerRad'>
          Νέα τραγούδια της μπάντας
        </h2>
        <div className='sectionStyle'>
          {allVideos.bandSongsVids.map((video) => (
            <div key={video.id}>
              <Video video={video} />
            </div>
          ))}
        </div>
      </div>
      <hr className='container' />
      <div id='videos' className='container'>
        <h2 className='container centered headerRad'>Videos</h2>
        <div className='sectionStyle'>
          {allVideos.tubeVids.map((video) => (
            <div key={video.id}>
              <Video video={video} />
            </div>
          ))}
        </div>
      </div>
      <hr className='container' />
      <div id='musicStories' className='container'>
        <h2 className='container centered headerRad'>Μουσικές ιστορίες</h2>
        <div className='sectionStyle'>
          {allVideos.musicStories.map((video) => (
            <div key={video.id}>
              <Video video={video} />
            </div>
          ))}
        </div>
      </div>
      <hr className='container' />
      <div id='shorts' className='container'>
        <h2 className='container centered headerRad'>Shorts</h2>
        <div className='sectionStyle'>
          {allVideos.shortsRow.map((short) => (
            <div key={short.id}>
              <Short short={short} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AllVideos;
