function Guests() {
  return (
    <div className='container'>
      <h2 className='centered, headerRad'>Συμμετοχές</h2>
      <p></p>
      <h3 className='container, centered'>
        Ελένη <br />
      </h3>
      <div className='centered'>
        <div className='card'>
          <div className='card-body'>
            <div className='embed-responsive embed-responsive-16by9'>
              <iframe
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/XBCsJYQywBU'
                title='Ελένη'
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <br />
      <p className='centered'>
        Μια δράση του project "Αναζητήστε τη γυναίκα" / "Cherchez la femme"
        <br />
      </p>
      <hr />
    </div>
  );
}

export default Guests;
