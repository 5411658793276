import React, { Component } from "react";

class Video extends Component {
  render() {
    return (
      <div className='card m-1'>
        <div className='card-body'>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe
              className='embed-responsive-item'
              width='100%'
              height='100%'
              title={this.props.video.title}
              src={this.props.video.link}
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
