/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import "./darkMode.css";
import Header from "./components/Header";
import Description from "./components/Description";
import Members from "./components/Members";
import FutureConcerts from "./components/FutureConcerts";
import PastPosters from "./components/PastPosters";
import Interviews from "./components/Interviews";
import Guests from "./components/Guests";
import AllVideos from "./components/AllVideos";

class App extends Component {
  centerSmall = {
    display: "flex",
    justifyContent: "center",
    fontSize: "11px",
  };

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerHeight = document.querySelector(".navbar").offsetHeight;
      const sectionTop = section.offsetTop - headerHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  render() {
    document.body.className = "dark";
    return (
      <>
        <div id='header'>
          <Header scrollToSection={this.scrollToSection} />
        </div>

        <div id='description'>
          <Description />
        </div>

        <div id='members'>
          <Members />
        </div>

        <div id='soon'>{/* <hr /> */}</div>

        <div id='futureConcerts'>
          <FutureConcerts />
        </div>

        <div id='pastPosters'>
          <PastPosters />
        </div>

        <div id='interviews'>
          <Interviews />
        </div>

        <div id='guests'>
          <Guests />
        </div>

        <div id='allVideos'>
          <AllVideos />
        </div>

        <hr />
        <div style={this.centerSmall}>
          Website programmed by Dimitris Psathas
        </div>
        <div style={this.centerSmall}>
          Band logo designed by George Petridis
        </div>
        <div style={this.centerSmall}>
          This website does not use cookies and does not collect user data of
          any kind
        </div>
      </>
    );
  }
}

export default App;
